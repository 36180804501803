
import React from "react";
import "./App.css";
import { Container, Row, Col, Image, Button, Form } from 'react-bootstrap';

import { FiPhone, FiMail } from 'react-icons/fi';
import { IoIosArrowDown } from 'react-icons/io';

import ImageLoader from 'react-imageloader';
import ReactLoading from 'react-loading';

const ICON_STYLE = {
    verticalAlign: 'middle',
    position: 'relative',
    top: '-1px',
    marginRight: '5px'
}

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            isAtTop: true
        };
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    handleScroll = () => {
        let newY = window.pageYOffset;

        this.setState({
            isAtTop: newY === 0
        });
    }

    renderFooter() {
        return <Row id="footer">
            <span style={{fontSize: '15px', color: 'white', textAlign: 'center', paddingTop: '30px', display: 'block'}}>

            </span>
        </Row>
    }

    handleSocialClick = (href) => {
        window.open(href, '_blank');
    }

    renderAboutArrow = () => {
        const {
            isAtTop
        } = this.state;

        const arrowStyleClass = isAtTop ? 'coverPageArrowShown' : 'coverPageArrowNotShown';

        return <div id={"coverPageArrow"} className={arrowStyleClass}>
            <IoIosArrowDown />
        </div>
    }

    renderAbout() {
        return <div id="coverPage">
            <div id="coverPageContent">
                <h1><span className="strongChar">D</span>ANIEL <span className="strongChar">W</span>ILSON <span className="strongChar">D</span>EAN</h1>
                
                <h2>PHOTOGRAPHER</h2>

                <span style={{display: 'block', marginTop: '40px'}}>
                    <a href="mailto:danielwilsondean@gmail.com" className="email contactType">
                        <FiMail style={ICON_STYLE} />
                        danielwilsondean@gmail.com
                    </a>

                    <a href="tel:+18149338307" className="phone contactType">
                        <FiPhone style={ICON_STYLE} />
                        (814) 933-8307
                    </a>
                </span>

                <div style={{
                    display: 'block',
                    marginTop: '30px',
                    width: '350px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    fontSize: '50px'
                }}>
            
                    <span className="findMeSocial" onClick={()=>{this.handleSocialClick('https://www.instagram.com/daniel.w.dean')}}>{this.__getInstaSVG()}</span>
                    <span className="findMeSocial" onClick={()=>{this.handleSocialClick('https://www.facebook.com/daniel.dean.7946281')}}>{this.__getFacebokSVG()}</span>
                    <span className="findMeSocial" onClick={()=>{this.handleSocialClick('https://www.pinterest.com/danielwdeanphotography')}}>{this.__getPinterestSVG()}</span>

                </div>
            </div>

            {this.renderAboutArrow()}
        </div>
    }

    buildImageWidget = (image, href) => {
        const imageBase = <ImageLoader 
            src={image}
            className="featureImage grow"
            preloader={this.renderLoadingWidget}
            >
        </ImageLoader>;


        if (href === '#') {
            return imageBase;
        }
        else {
            return <a href={href} className="aImgWrapper" target="about:blank">{imageBase}</a>;
        }
    }

    renderImageRowFull = (image, href) => {
        const imageWidget = this.buildImageWidget(image, href);

        return <Row className="imageRow imageRowEven">
            <div className="imageRowContent noGrow">
                {imageWidget}
            </div>
        </Row>
    }

    renderImageRowEven = (image, href) => {
        const imageWidget = this.buildImageWidget(image, href);

        return <Row className="imageRow">
            <Col sm={1}></Col>

            <Col sm={8}>
                {imageWidget}
            </Col>
            <Col sm={2}>
                
            </Col>

            <Col sm={1}></Col>
        </Row>
    }

    renderImageRowOdd = (image, href) => {
        const imageWidget = this.buildImageWidget(image, href);

        return <Row className="imageRow">
            <Col sm={1}></Col>

            <Col sm={2}>
                
            </Col>
            <Col sm={8}>
                {imageWidget}
            </Col>

            <Col sm={1}></Col>
        </Row>
    }

    renderLoadingWidget = () => {
        return <div style={{display: 'block', width: '64px', margin: 'auto'}}>
            <ReactLoading color={"black"} type={"bars"} />
        </div>
    }

    render() {
        return (
            <div id="body-wrapper">
                <Container style={{maxWidth: 'unset', padding: '0', margin: '0'}}>

                    {this.renderAbout()}

                    {/* Dogs */}
                    { this.renderImageRowFull("img/gallery/m_7R30593.jpg", "#") }

                    {/* Wedding */}
                    <Row className="imageRow">
                        <Col sm={10}>
                            <span id="specialImage1">{ this.buildImageWidget("img/gallery/mwedding3.jpg", "#") }</span>
                        </Col>
                        <Col sm={2}>
                            <span id="specialImage2">{ this.buildImageWidget("img/gallery/mwedding1.jpg", "#") }</span>
                        </Col>
                    </Row>
                    { this.renderImageRowFull("img/gallery/mwedding2.jpg", "#") }


                    {/* Fudge */}
                    <Row className="imageRow">
                        <Col sm={6}>
                            <span id="specialImage1">{ this.buildImageWidget("img/gallery/product_01.jpg", "https://chloesfudgetopping.com") }</span>
                        </Col>
                        <Col sm={6}>
                            <span id="specialImage1">{ this.buildImageWidget("img/gallery/product_07.jpg", "https://chloesfudgetopping.com") }</span>
                        </Col>
                    </Row>
                    { this.renderImageRowFull("img/gallery/mproduct_03.jpg", "https://chloesfudgetopping.com") }

                    {/* Flags */}
                    { this.renderImageRowOdd("img/gallery/m_7R31394.jpg", "#") }
                    
                    {/* Horses */}
                    { this.renderImageRowFull("img/gallery/m_7R32003.jpg", "#") }

                    {/* Squirrel & Laptop*/}
                    <Row className="imageRow">
                        <Col sm={6}>
                            <span id="specialImage1">{ this.buildImageWidget("img/gallery/m_7R31109.jpg", "#") }</span>
                        </Col>
                        <Col sm={6}>
                            <span id="specialImage1">{ this.buildImageWidget("img/gallery/m_DSC0286.jpg", "#") }</span>
                        </Col>
                    </Row>

                    {/* Road */}
                    { this.renderImageRowFull("img/gallery/m_7R30367.jpg", "#") }

                    {/* Flowers                     */}
                    { this.renderImageRowEven("img/gallery/m_7R30704.jpg", "#") }

                    {/* Beach */}
                    { this.renderImageRowFull("img/gallery/m_7R30547.jpg", "#") }


                    <Row style={{textAlign: 'center', marginTop: '100px', marginBottom: '100px'}}>
                        <Col sm={3}></Col>
                        <Col sm={6}>
                            <h2><strong>Event & Wedding Photography</strong></h2>
                            <h3>Williamsport, PA</h3>
                            <p>Please feel free to reach out and contact me about any photography needs you have.</p>
                            <p>                <a href="mailto:danielwilsondean@gmail.com" className="email contactType">
                    <FiMail style={ICON_STYLE} />
                    danielwilsondean@gmail.com
                </a></p>

                            <br/><br/>
                        </Col>
                        <Col sm={3}></Col>
                    </Row>
                </Container>
            </div>
        );
    }

    __getFacebokSVG = () => {
        return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-3 7h-1.924c-.615 0-1.076.252-1.076.889v1.111h3l-.238 3h-2.762v8h-3v-8h-2v-3h2v-1.923c0-2.022 1.064-3.077 3.461-3.077h2.539v3z"/></svg>
    }

    __getPinterestSVG = () => {
        return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-7 20c-.825 0-1.62-.125-2.369-.357.326-.531.813-1.402.994-2.098l.499-1.901c.261.498 1.023.918 1.833.918 2.414 0 4.152-2.219 4.152-4.976 0-2.643-2.157-4.62-4.933-4.62-3.452 0-5.286 2.317-5.286 4.841 0 1.174.625 2.634 1.624 3.1.151.07.232.039.268-.107l.222-.907c.019-.081.01-.15-.056-.23-.331-.4-.595-1.138-.595-1.825 0-1.765 1.336-3.472 3.612-3.472 1.965 0 3.341 1.339 3.341 3.255 0 2.164-1.093 3.663-2.515 3.663-.786 0-1.374-.649-1.185-1.446.226-.951.663-1.977.663-2.664 0-.614-.33-1.127-1.012-1.127-.803 0-1.448.831-1.448 1.943 0 .709.239 1.188.239 1.188s-.793 3.353-.938 3.977c-.161.691-.098 1.662-.028 2.294-2.974-1.165-5.082-4.06-5.082-7.449 0-4.418 3.582-8 8-8s8 3.582 8 8-3.582 8-8 8z"/></svg>
    }

    __getInstaSVG = () => {
        return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M11.984 16.815c2.596 0 4.706-2.111 4.706-4.707 0-1.409-.623-2.674-1.606-3.538-.346-.303-.735-.556-1.158-.748-.593-.27-1.249-.421-1.941-.421s-1.349.151-1.941.421c-.424.194-.814.447-1.158.749-.985.864-1.608 2.129-1.608 3.538 0 2.595 2.112 4.706 4.706 4.706zm.016-8.184c1.921 0 3.479 1.557 3.479 3.478 0 1.921-1.558 3.479-3.479 3.479s-3.479-1.557-3.479-3.479c0-1.921 1.558-3.478 3.479-3.478zm5.223.369h6.777v10.278c0 2.608-2.114 4.722-4.722 4.722h-14.493c-2.608 0-4.785-2.114-4.785-4.722v-10.278h6.747c-.544.913-.872 1.969-.872 3.109 0 3.374 2.735 6.109 6.109 6.109s6.109-2.735 6.109-6.109c.001-1.14-.327-2.196-.87-3.109zm2.055-9h-12.278v5h-1v-5h-1v5h-1v-4.923c-.346.057-.682.143-1 .27v4.653h-1v-4.102c-1.202.857-2 2.246-2 3.824v3.278h7.473c1.167-1.282 2.798-2 4.511-2 1.722 0 3.351.725 4.511 2h7.505v-3.278c0-2.608-2.114-4.722-4.722-4.722zm2.722 5.265c0 .406-.333.735-.745.735h-2.511c-.411 0-.744-.329-.744-.735v-2.53c0-.406.333-.735.744-.735h2.511c.412 0 .745.329.745.735v2.53z"/></svg>
    }
}

export default App;